import React from "react";
import { IRenderSearchResultsProps, SiteSearchResultModel } from "../Contracts/contracts";
import "../Style/SiteSearch.scss";

export const RenderSearchResults = (props: IRenderSearchResultsProps) => {
	const { results } = props;

	if (!results?.results) return <></>;

	return (
		<div className="search-results-wrapper">
			{`Total results: ${results.total}`}
			{results?.results?.map((result: SiteSearchResultModel) => {
				return (
					<div className="search-results-item" key={result.id}>
						<a href={`${result.urlName}`}>
							<p className="search-results-item-heading">{result.pageHeading}</p>
						</a>
						<p className="search-results-item-date">
							{result.createDate ? `Created on: ${result.createDate} ` : ""}
						</p>
						<p className="search-results-item-date">
							{result.updateDate ? `Last updated on: ${result.updateDate}` : ""}
						</p>
					</div>
				);
			})}
		</div>
	);
};
