import "../../common/polyfills";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { SiteSearch } from "./Components/SiteSearch";

function MountSiteSearch(props: any, el: Element) {
	ReactDOM.render(<SiteSearch {...props} />, el);
}

(window as any).MountSiteSearch = MountSiteSearch;
